import React from 'react'
import '../About.css';
import { SiPhp, SiMysql } from 'react-icons/si';
import { AiFillHtml5, AiFillGoogleCircle } from 'react-icons/ai';
import { DiCss3, DiReact, DiJavascript1, DiPhotoshop } from 'react-icons/di';
import Jm from '../images/jm.jpg'; 
 
function About() {
    return (
        <div className='about'>
            <h1>ABOUT</h1>

            <img src={Jm} alt="Me" className='JM--icon' />

            <p>Hi, I'm Joe. A full-stack web developer, IT technician and G-Suite administrator from London, UK.</p>
            <p>I specialise in responsive web design, bespoke content management systems and mobile based web applications.</p>
            <p>I am proficient in:</p>
        <div className="list--wrapper">
            <ul>
                <li><p>HTML5</p></li>
                <li><p>CSS3</p></li>
                <li><p>ReactJS</p></li>
                <li><p>JavaScript</p></li>
                <li><p>PHP</p></li>
                <li><p>MySQL</p></li>
                <li><p>Photoshop</p></li>
            </ul>
        </div>

            <div className="about--icon--wrapper">
                <AiFillHtml5 className="about--icons" />
                    <DiCss3 className="about--icons" />
                    <DiReact className="about--icons" />
                    <DiJavascript1 className="about--icons" />
                    <SiPhp className="about--icons" />
                    <SiMysql className="about--icons" />
                    <DiPhotoshop className="about--icons" />
                    <AiFillGoogleCircle className="about--icons" />
                </div> 
        </div>
    );
}

export default About;
