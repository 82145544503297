import React from 'react'
import '../Contact.css';
import { BiErrorCircle } from 'react-icons/bi';

      function FourOhFour() {

          return (
     
            <div className='contact'>
              <h1>404</h1>

              <BiErrorCircle className="contact--icons" />

              <p>Page Not Found</p>

            </div>
       ); 
      }
  
  export default FourOhFour;