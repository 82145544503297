import React from 'react'
import '../Contact.css';
import { HiOutlineMail } from 'react-icons/hi';

      function Sent() {

          return (
     
            <div className='contact'>
              <h1>CONTACT</h1>

              <HiOutlineMail className="contact--icons" />

              <p>Your message has been delivered</p>
          
            </div>

                ); 
            } 
  
  export default Sent;