import React from 'react'
import PortfolioShow from '../PortfolioShow';
import '../Portfolio.css';
import { AiFillFolderOpen } from 'react-icons/ai';

function Portfolio() {
    return (
        <div className='portfolio'>
            <h1>PORTFOLIO</h1>
            <AiFillFolderOpen className="portfolio--icons" />
            <div className="cards__container">
                <div className="cards__wrapper">
                    
                    <ul className="cards__items">
                        
                        <PortfolioShow
                        src="images/port-jmd.png" text="Portfolio design created using React-JS" label="Website" path="portfolio"
                        />
                        
                        <PortfolioShow 
                        src="images/jm-port.jpg" text="Future projects will be displayed here" label="Web App" path="portfolio"
                        />

                        <PortfolioShow 
                        src="images/jm-port.jpg" text="Future projects will be displayed here" label="Game" path="portfolio"
                        />
                    </ul>

                    <ul className="cards__items">
                        
                        <PortfolioShow 
                        src="images/jm-port.jpg" text="Future projects will be displayed here" label="Website" path="portfolio"
                        />

                        <PortfolioShow 
                        src="images/jm-port.jpg" text="Future projects will be displayed here" label="Web App" path="portfolio"
                        />

                        <PortfolioShow 
                        src="images/jm-port.jpg" text="Future projects will be displayed here" label="Game" path="portfolio"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default Portfolio;
