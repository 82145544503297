import React from 'react'
import '../Contact.css';
import { HiOutlineMail } from 'react-icons/hi';

      function Error() {

          return (
     
            <div className='contact'>
              <h1>CONTACT</h1>

              <HiOutlineMail className="contact--icons" />

              <p>ERROR - Please try again</p>

            </div>
       ); 
      }
  
  export default Error;