import React from 'react'
import '../Contact.css';
import emailjs from 'emailjs-com';
import { HiOutlineMail } from 'react-icons/hi';

    function sendEmail(e) {

        e.preventDefault();
    
        emailjs.sendForm('service_iinc5gy', 'template_qsjfi0h', e.target, 'user_3bq8MTi1EYbSG5ao8rg7x')
            .then((result) => {
                console.log(result.text);
                window.location.replace("/sent");
                }, (error) => {
                window.location.replace("/error");
        });
    }
  
    function Contact() {
        var loadjs = require('loadjs');
        loadjs('https://www.google.com/recaptcha/api.js', function() {
    });

    return (

        <div className='contact'>

            <h1>CONTACT</h1>
                
                <HiOutlineMail className="contact--icons" />

                <form id="cap" onSubmit={sendEmail} >  
                    <input name="user_name" type="text" className="feedback-input" placeholder="Name" required/>   
                    <input name="user_email" type="text" className="feedback-input" placeholder="Email" required/>
                    <textarea name="message" className="feedback-input" placeholder="Message" required />
                    <div className="g-recaptcha" data-sitekey="6LdeHdkZAAAAAALUKzJerK7BBxacvIFzdZzyxcE_" data-size="compact"></div>

                    {/* ADD SECRET KEY TO EMAILJS TEMPLATE */}

                    <input type="submit" value="Submit"/>
                </form>
        </div>

        );    
    }

export default Contact;
