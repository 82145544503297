import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Portfolio from './components/pages/Portfolio';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Home from './components/pages/Home';
import Sent from './components/pages/Sent';
import Error from './components/pages/Error';
import FourOhFour from './components/pages/404';
import './App.css';

function App() {

  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/portfolio' exact component={Portfolio} />
          <Route path='/about' exact component={About} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/sent' exact component={Sent} />
          <Route path='/error' exact component={Error} />
          <Route path='/404' exact component={FourOhFour} />
        </Switch>
      </Router>
    </>
  );
}

export default App;