import React from 'react';

function PortfolioShow(props) {
    return (
        <>
         <li className="cards__item">
            <div className="cards__item__link" to={props.path}>
                <figure className="cards__item__pic-wrap" data-category={props.label}>
                <a href={props.src}><img src={props.src} alt="" className="cards__item__img"/></a>
                </figure>
                <div className="cards__item__info">
                    <h5 className="cards__item__text">{props.text}</h5>
                </div>   
            </div>    
         </li>
         
        </>
    )
}

export default PortfolioShow;
